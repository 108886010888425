import { DIALOG_DATA } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import {
    AfterViewInit,
    Component,
    ElementRef,
    HostListener,
    Inject,
    OnDestroy,
    OnInit,
    ViewChild,
    effect,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

import {
    ComponentFactoryConfig,
    ComponentFactoryDirective,
} from '../../componentFactory/component-factory.directive';
import { ActionBarComponent } from '../action-bar/action-bar.component';
import { ActionDescriptor, DialogBuilderDescriptor } from '../dialog-builder.models';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'app-dialog-builder-base',
    templateUrl: './dialog-builder-base.component.html',
    styleUrls: ['./dialog-builder-base.component.scss'],
    standalone: true,
    imports: [
        MatDialogModule,
        CommonModule,
        MatButtonModule,
        ActionBarComponent,
        ComponentFactoryDirective,
        MatIconModule,
    ],
})
export class DialogBuilderBaseComponent implements OnInit, AfterViewInit, OnDestroy {
    private _destroy: Subject<void> = new Subject();
    @ViewChild(ComponentFactoryDirective, { static: true }) anchor: ComponentFactoryDirective;

    dialogRef: MatDialogRef<any>;

    headerConfig?: ComponentFactoryConfig;
    contentConfig?: ComponentFactoryConfig;
    actionsConfig?: ComponentFactoryConfig;

    constructor(
        @Inject(DIALOG_DATA) public data: DialogBuilderDescriptor,
        private elementRef: ElementRef
    ) {
        effect(() => {
            let code = this.data.executeActionSignal();
            const action = this.data.actions.dialogActions.find(
                (action: ActionDescriptor) => action.code === code
            );
            if (code && !action.manualClose) {
                this.close();
            }
        });
    }

    ngOnInit(): void {
        if (this.data.header.componentConfig) {
            this.loadCustomComponent('header');
        }
        if (this.data.content.componentConfig) {
            this.loadCustomComponent('content');
        }
        if (this.data.actions.componentConfig) {
            this.loadCustomComponent('actions');
        }
    }

    ngAfterViewInit(): void {
        this.dialogRef
            .backdropClick()
            .pipe(takeUntil(this._destroy))
            .subscribe(() => {
                const dismissAction = this.data.actions.dialogActions.find(
                    action => action.dismiss
                );
                if (dismissAction) {
                    this.data.executeActionSignal.set(dismissAction.code);
                }
            });
    }
    ngOnDestroy(): void {
        this._destroy.next();
        this._destroy.complete();
    }

    /**
     * Creates the configuration of the custom component.
     * @param type
     */
    loadCustomComponent(type: 'header' | 'content' | 'actions'): void {
        this[`${type}Config`] = {
            component: this.data[type].componentConfig.component,
            data: new Map([
                ['componentData', this.data[type].componentConfig.componentData],
                ['executeActionSignal', this.data.executeActionSignal],
                ['closeDialog', this.close.bind(this)],
            ]),
        };
    }

    /**
     * Closes the dialog
     */
    close(result?: any): void {
        this.dialogRef.close(result);
    }
}
