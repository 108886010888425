<div
    class="bg-dialog flex flex-col gap-2 dialogBuilderBaseContainer rounded-2xl h-full"
    [ngClass]="'dialog-size-' + data.dialogSize">
    <!-- #region Header -->

    <!-- Default header -->
    <div class="p-2 border-b border-slate-100">
        <div *ngIf="!data.header.componentConfig" class="flex flex-row justify-between">
            <div class="text-2xl my-auto" [innerHTML]="data.header.title"> </div>
            <button *ngIf="data.header.showCloseButton" mat-icon-button (click)="close()">
                <mat-icon svgIcon="mat_solid:close"></mat-icon>
            </button>
        </div>

        <!-- Custom header component -->
        <ng-template
            componentFactory
            [config]="headerConfig"
            class="flex-1 overflow-auto"></ng-template>
    </div>
    <!-- #endregion -->

    <!-- #region Content -->

    <div class="flex-1 overflow-auto">
        <!-- Default content - description -->
        <div *ngIf="data.content.description" class="px-2 w-full flex flex-row gap-2">
            <!-- Icon -->
            <ng-container *ngIf="data.content.icon?.show">
                <div
                    class="flex flex-0 items-center justify-center w-10 h-10 sm:mr-4 rounded-full"
                    [ngClass]="{
                        'text-primary-600 bg-primary-100 dark:text-primary-50 dark:bg-primary-600':
                            data.content.icon.color === 'primary',
                        'text-accent-600 bg-accent-100 dark:text-accent-50 dark:bg-accent-600':
                            data.content.icon.color === 'accent',
                        'text-warn-600 bg-warn-100 dark:text-warn-50 dark:bg-warn-600':
                            data.content.icon.color === 'warn',
                        'text-gray-600 bg-gray-100 dark:text-gray-50 dark:bg-gray-600':
                            data.content.icon.color === 'basic',
                        'text-blue-600 bg-blue-100 dark:text-blue-50 dark:bg-blue-600':
                            data.content.icon.color === 'info',
                        'text-green-500 bg-green-100 dark:text-green-50 dark:bg-green-500':
                            data.content.icon.color === 'success',
                        'text-amber-500 bg-amber-100 dark:text-amber-50 dark:bg-amber-500':
                            data.content.icon.color === 'warning',
                        'text-red-600 bg-red-100 dark:text-red-50 dark:bg-red-600':
                            data.content.icon.color === 'error',
                    }">
                    <mat-icon class="text-current" [svgIcon]="data.content.icon.name"></mat-icon>
                </div>
            </ng-container>
            <div class="" [innerHTML]="data.content.description"> </div>
        </div>

        <!-- Custom content component -->
        <ng-template
            componentFactory
            [config]="contentConfig"
            class="flex-1 overflow-auto"></ng-template>
    </div>
    <!-- #endregion -->

    <!-- #region Actions -->

    <!-- Default actions -->
    <div class="p-2">
        <div *ngIf="!data.actions.componentConfig" class="flex flex-row justify-end">
            <action-bar
                [executeActionSignal]="data.executeActionSignal"
                [actionDescriptors]="data.actions.dialogActions"></action-bar>
        </div>

        <!-- Custom actions component -->
        <ng-template
            componentFactory
            [config]="actionsConfig"
            class="flex-1 overflow-auto"></ng-template>
    </div>

    <!-- #endregion -->
</div>
