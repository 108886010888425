import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogBuilderBaseComponent } from './dialog-builder-base/dialog-builder-base.component';
import { DialogBuilderConfig } from './dialog-builder.models';

@Injectable({
    providedIn: 'root',
})
export class DialogBuilderService {
    constructor(public dialog: MatDialog) {}

    /**
     * Opens a dialog with the given configuration
     * @param config The configuration of the desired dialog
     * @returns The reference of the opened dialog as DialogRef<any, any>
     */
    openDialog(config: DialogBuilderConfig): MatDialogRef<any, any> {
        if (config.descriptor.executeActionSignal) {
            config.descriptor.executeActionSignal.set(null);
        }

        let fullscreen: any = {};
        if (config.descriptor.dialogSize === 'fullscreen') {
            fullscreen = {
                width: '98vw',
                height: '98vh',
                maxWidth: '98',
                maxHeight: '98',
                panelClass: 'full-screen-dialog-container',
            };
        }
        let dialogRef = this.dialog.open(DialogBuilderBaseComponent, {
            ...config.config,
            ...fullscreen,
            data: config.descriptor,
            autoFocus: true,
            disableClose: !config.descriptor.actions.dialogActions.some(action => action.dismiss),
        });

        dialogRef.componentInstance.dialogRef = dialogRef;

        return dialogRef;
    }
}
