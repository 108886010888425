import { Component, Input, OnChanges, OnInit, SimpleChanges, WritableSignal } from '@angular/core';

@Component({
    template: '',
    standalone: true,
})
export class DialogBuilderComponent<T> implements OnInit, OnChanges {
    @Input() componentData?: any;
    @Input() executeActionSignal?: WritableSignal<string>;
    @Input() closeDialog: (result?: any) => void;

    /**
     * The model of the component.
     */
    componentModel?: T;

    constructor() {}
    ngOnInit(): void {
        this.componentModel = this.componentData.model;
    }
    ngOnChanges(changes: SimpleChanges): void {
        if (changes['componentData']) {
            this.componentData = changes['componentData'].currentValue;
            this.componentModel = this.componentData.model;
        }
    }
}
